import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
// Models
import { ProductMasterModel } from '../_models/product-master.model';
import { map, catchError, tap } from 'rxjs/operators';
import { ExcelService } from '../../../_base/crud/services/excel.service';
import { UpdateInventoryModel } from '../_models/updateInventory.model';

@Injectable({
  providedIn: 'root'
})
export class ProductmasterService {

  constructor(
    private http: HttpClient,
		private excelService: ExcelService
  ) { }

  //method to get all the todos as you can see it return an observable
  getProducts(product): Observable<ProductMasterModel[]> {
		return this.http.post<ProductMasterModel[]>('api/CRM/Leads', product);
  }

  findProducts(from: number, to: number, search: string): Observable<any> {
		const params =
		{
			'from': from,
			'to': to,
			'text': search
		};
		return this.http.get('api/CRM/MasterProducts?From='+from+'&Text='+search+'&To='+to)
			.pipe(
				map(res => res)
			);
	}

	// add product
	addProduct(product): Observable<any> {
		return this.http.post('api/CRM/AddProduct', product)
			.pipe(
				map(res => res)
			);
	}

	// update product
	updateProduct(product): Observable<any> {
		return this.http.post('api/CRM/UpdateProduct', product)
			.pipe(
				map(res => res)
			);
  }

	// file upload
	fileUpload(file: any): Observable<any> {
		return this.http.post<any>('api/CRM/ProductsBulkUpload', file);
	}

	// file update
	fileUpdte(file: any): Observable<any> {
		return this.http.post<any>('api/CRM/ProductsBulkUpdate', file);
	}

	// export to excel
	fileExport(product): Observable<any> {
		const params =
		{
			CategoryIds: "",
			From: 1,
			SKUCode: "",
			Text: product.search,
			To: -1
		};
		return this.http.post('api/CRM/Products/ExportToExcel', params, { responseType: 'arraybuffer' })
			.pipe(
				map((res) => {
					return res;
				}),
				tap(
					data => this.excelService.saveAsExcelFile(data, 'Products'),
					error => console.log(error),
				),
				catchError(err => {
					return null;
				})
			);
	}

	// delete product
	deleteProduct(productId: number): Observable<ProductMasterModel> {
		return this.http.delete<ProductMasterModel>('api/CRM/Products/' + productId);
	}

	// delete child product
	deleteChildProduct(productId: number): Observable<ProductMasterModel> {
		return this.http.delete<ProductMasterModel>('api/CRM/ChildProduct/' + productId);
	}

	fetchinventorylogs(productId: number): Observable<ProductMasterModel[]> {
		return this.http.get<ProductMasterModel[]>('api/CRM/ProductInventory/'+ productId);
	}

	updateinventory(product: UpdateInventoryModel): Observable<UpdateInventoryModel>{
		return this.http.put<UpdateInventoryModel>('api/CRM/ProductInventory', product);
	}

	getChildCategories(): Observable<any>{
		return this.http.get('api/CRM/ChildCategories')
			.pipe(
				map(res => res)
			);
	}

	getProductDetail(productId): Observable<any>{
		return this.http.get('api/CRM/Product?ProductId=' + productId)
			.pipe(
				map(res => res)
			);
	}

	addChildProduct(product): Observable<any>{
		return this.http.post('api/CRM/AddChildProduct', product)
			.pipe(
				map(res => res)
			);
	}

	getChildProduct(masterProductId, text, to): Observable<any>{
		return this.http.get('api/CRM/ChildProducts?MasterProductId='+ masterProductId + '&Text=' + text + '&To=' + to)
			.pipe(
				map(res => res)
			);
	}

	getChildProducts(categoryId): Observable<any>{
		return this.http.get('api/CRM/ChildProducts?CategoryId=' + categoryId + '&From=1&MasterProductId=0&To=20')
			.pipe(
				map(res => res)
			);
	}

	//ShowBulkUploadProductFile
	ShowProductBulkUploadFileName(loadtype: string,from: number, to: number, search: string):Observable<any>{
        return  this.http.get('api/CRM/Products/BulkUpload/FileName?loadtype='+loadtype+'&from=' +from + '&text=' + search + '&to=' + to)
        .pipe(map(resp=>{
            return resp;
        }));
	}

	//BulkUploadExportReport
	BulkUploadExportReport(File_Id):Observable<any>
	{
		return this.http.post('api/CRM/products/bulkUpload/ExportReport/'+File_Id,File_Id,{ responseType: 'arraybuffer' })
		.pipe(
			map((res) => {
				return res;
			}),
			tap(
				data => this.excelService.saveAsExcelFile(data, 'ProductsBulkUploadReport'),
				error => console.log(error),
			),
			catchError(err => {
				return null;
			})
		);
	}

	//ProductsBulkUploadViewReport
	BulkUploadViewReport(File_Id:number,from: number, to: number, search: string):Observable<any>{
		const params =
		{	'File_Id':File_Id,
			'from': from,
			'to': to,
			'text': search
		};
		return this.http.get('api/CRM/Products/BulkUpload/ViewReport?Id='+File_Id +'&from=' +from + '&text=' + search + '&to=' + to);
	}

	//ProductsBulkUploadFile_Report
	BulkUploadProductFileReport(File_Id):Observable<any>
	{
		return this.http.post('api/CRM/products/BulkUpload/ExportFileReport/'+File_Id,File_Id,{ responseType: 'arraybuffer' })
		.pipe(
			map((res) => {
				return res;
			}),
			tap(
				data => this.excelService.saveAsExcelFile(data, 'ProductsBulkUploadFileReport'),
				error => console.log(error),
			),
			catchError(err => {
				return null;
			})
		);
	}

}
