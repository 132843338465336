// Angular
import { Component, Inject, OnInit, ChangeDetectionStrategy, Input, forwardRef, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// CRUD
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';
import { MatDialog } from '@angular/material';
import { SelectListDialogComponent } from '../select-list-dialog/select-list-dialog.component';

// describes what the return value of the form control will look like
export interface SelectListInputFormValues {
	selectInputValue: number;
	selectInputText: string;
}

@Component({
	selector: 'kt-select-list-input',
	templateUrl: './select-list-input.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectListInputComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SelectListInputComponent),
			multi: true,
		}
	]

})
export class SelectListInputComponent implements ControlValueAccessor, OnDestroy {

	@ViewChild('imageFileInput', { static: true }) imageFileInput: ElementRef;
	@Input() placeholder: string;
	@Input() title: string;
	@Input() list: any = [];
	@Input() resValue: string = '';
	@Input() resText: string = '';
	@Input() isRequired: boolean = false;
	@Input() isClear: boolean = false;
	@Input() text: string;
	@Input() statusId: number;
	@Output() selectedValue:any = new EventEmitter()

	form: FormGroup;
	subscriptions: Subscription[] = [];

	get value(): SelectListInputFormValues {
		return this.form.value;
	}

	set value(value: SelectListInputFormValues) {
		this.form.setValue(value);
		this.onChange(value);
		this.onTouched();
	}

	constructor(
		private dialog: MatDialog,
		private formBuilder: FormBuilder,
		private translate: TranslateService,
	) {
		// create the inner form
		this.form = this.formBuilder.group({
			selectInputValue: [''],
			selectInputText: ['', Validators.required]
		});

		this.subscriptions.push(
			// any time the inner form changes update the parent of any change
			this.form.valueChanges.subscribe(value => {
				this.onChange(value);
				this.onTouched();
			})
		);
	}

	ngOnChanges(){
		if(this.isClear){
			this.form.reset();
		}
		this.form.patchValue({
			selectInputValue: this.statusId,
			selectInputText: this.text,
		});
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	registerOnChange(fn) {
		this.onChange = fn;
	}

	writeValue(value) {
		if (value) {
			this.value = value;
		}
	}

	registerOnTouched(fn) {
		this.onTouched = fn;
	}

	// communicate the inner form validation to the parent form
	validate(_: FormControl) {
		return this.form.valid ? null : { image: { valid: false, }, };
	}

	/**
	 * Open select list
	 */
	openList() {
		const title: string = this.translate.instant(this.title);
		const list = this.list;
		const value = this.resValue;
		const text = this.resText;
		const submitButtonText = this.translate.instant('Done');

		const dialogRef = this.dialog.open(SelectListDialogComponent, {
			data: { title, list, submitButtonText, text, value },
			width: '250px'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (!res)
				return;
			this.form.patchValue({
				selectInputValue: res[this.resValue],
				selectInputText: res[this.resText],
			});
			// this.selectedValue.emit(this.form.get('selectInputText').value)
		});
	}

	closeList(){
		this.form.reset();
	}

}
