import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
// Material
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
// RxJS
import { Subscription, Observable, ReplaySubject, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
// Services and Models
import { CustomerModel, CustomersService } from "../../../../../core/crm/customers";
import { ReferralCustomerModel } from "../../../../../core/crm/customers/_models/customer.model";
import { AuthService } from "../../../../../core/auth";
import { AddressService } from "../../../../../core/_base/crud";
import { CustomDatePipe } from "../../../../../core/_base/layout/index";
import { AddressDialogComponent } from "../address-dialog/address-dialog.component";
import { MyreferralsService } from "../../../../../core/myreferrals/myreferrals.service";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";

@Component({
	// tslint:disable-next-line:component-selector
	selector: "kt-customers-edit-dialog",
	templateUrl: "./customer-edit.dialog.component.html",
	styles: [
		`
			.cross {
				position: absolute;
				top: 20px;
				right: 0;
				height: 20px;
				width: 20px;
				padding: 4px 6px;
				line-height: 0.9;
				border: 1px solid rgba(0, 0, 0, 0.7);
				border-radius: 50%;
				background: #faf7f7;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class CustomerEditDialogComponent implements OnInit, OnDestroy {
	customer: CustomerModel;
	multiFilterCtrl: FormControl = new FormControl();
	customOptionFilterCtrl: FormControl = new FormControl();
	protected _onDestroy = new Subject<void>();	
  	filteredCustomOptions: any[] = [];
  	filteredOptionSubject: { [key: string]: ReplaySubject<any[]> } = {};
	customerForm: FormGroup;
	hasFormErrors: boolean = false;
	countryList = [];
	stateList = [];
	cityList = [];
	localityList = [];
	sourceList = [];
	stageList = [];
	typeList = [];
	parentCustomerList = [];
	filterparentCustomer: any =[];
	filterArrayCountry = [];
	filterArrayState = [];
	filterArrayCity = [];
	filterArrayLocality = [];
	territoryList = [];
	filteredOptions: Observable<string[]>;
	tagList = [];
	designationList: any = [];
	customFields = [];
	option = [];
	hostName: any = window.location.hostname;
	frontViewRemove: boolean = false;
	backViewRemove: boolean = false;
	// viewLoading: boolean = false;
	isCustomer: boolean = true;
	sameMobileNo: string;
	currentUser: any;
	longitude : any
	latitude : any 
	sameEmail: string;
	private componentSubscriptions: Subscription;
	data1: string;
	referralList: any;
	referralListArray: [];
	businessData: any = [];
	ReferredBy: any;
	viewLoading: boolean;
	action: any;
	rows = 1;
	currentCustomFieldId: string;
	@ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

	constructor(public dialogRef: MatDialogRef<CustomerEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private ref: ChangeDetectorRef, private authService: AuthService, private customersService: CustomersService, private customDatePipe: CustomDatePipe, private dialog: MatDialog, private myreferralsService: MyreferralsService, private addressService: AddressService) {}

	ngOnInit() {
		// console.log(this.data.customer);

		this.authService.getPermissions().subscribe((x) => (this.currentUser = x.currentUser));

		this.customer = this.data.customer;
		this.action = this.data.action;

		this.getCustomFields();
		this.createForm();
		this.getCountryList();
		this.getTypeList();
		this.getSourceList();
		this.getStageList();
		this.getTerritoryList();
		this.getTagList();
		this.getDesignationList();
		this.getReferalList();
		this.ref.detectChanges();

		this.customerForm.valueChanges.subscribe((val) => {});

		this.filteredOptions = this.customerForm.controls["PersonDesignation"].valueChanges.pipe(
			startWith(""),
			map((value) => this.designationFilter(value))
		);
		
		this.customOptionFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterOption();
        });
	}


	filterOption() {
		const customFieldId = this.currentCustomFieldId;
		const search = this.customOptionFilterCtrl.value ? this.customOptionFilterCtrl.value.toLowerCase() : '';
	
		const filteredOptions = this.filteredCustomOptions[this.currentCustomFieldId].filter(option => option.Value.toLowerCase().includes(search));
	
		if (!this.filteredOptionSubject[customFieldId]) {
		  this.filteredOptionSubject[customFieldId] = new ReplaySubject<any[]>(1);
		}
		this.filteredOptionSubject[customFieldId].next(filteredOptions);
	}

	updateFilteredArray( customFieldId: string) {
		this.currentCustomFieldId = customFieldId;
    	//this.filteredCustomOptions = options;
    	// if (!this.filteredOptionSubject[customFieldId]) {
		// 	this.filteredOptionSubject[customFieldId] = new ReplaySubject<any[]>(1);
    	// }
    	// this.filteredOptionSubject[customFieldId].next(this.filteredCustomOptions.slice());
	}


	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	createForm() {
		this.customerForm = this.fb.group({
			LeadName: [this.customer.LeadName, Validators.compose([Validators.required, Validators.maxLength(200)])],
			RefNo: [this.customer.RefNo, Validators.compose([Validators.maxLength(200)])],
			ReferredBy: [this.customer.ReferredById],
			PersonName: [this.customer.PersonName, Validators.compose([Validators.maxLength(200), Validators.pattern("^[a-zA-Z][a-zA-Z-' ]*[a-zA-Z ]$")])],
			MobileNo: [this.customer.MobileNo, Validators.compose([Validators.maxLength(15)])],
			ContactNo: [this.customer.ContactNo, Validators.compose([Validators.maxLength(15)])],
			Email: [this.customer.Email, Validators.compose([Validators.email, Validators.maxLength(100)])],
			PersonDesignation: [this.customer.PersonDesignation, Validators.compose([Validators.maxLength(200), Validators.pattern("^[a-zA-Z][a-zA-Z-' ]*[a-zA-Z ]$")])],
			Address: [this.customer.Address, Validators.compose([Validators.maxLength(300)])],
			CountryId: [this.customer.CountryId],
			StateId: [this.customer.StateId],
			CityId: [this.customer.CityId],
			LocalityId: [this.customer.LocalityId],
			PinCode: [this.customer.PinCode, Validators.compose([Validators.maxLength(10)])],
			LeadTags: [this.customer.LeadTags],
			LeadSourceId: [this.customer.LeadSourceId],
			LeadStageId: [this.customer.LeadStageId],
			TerritoryId: [this.customer.TerritoryId],
			Website: [this.customer.Website, Validators.compose([Validators.maxLength(200)])],
			OtherInfo: [this.customer.OtherInfo, Validators.compose([Validators.maxLength(500)])],
			FrontView: [this.customer.FrontView],
			BackView: [this.customer.BackView],
			LeadTypeId: [this.customer.LeadTypeId],
			ParentLeadId: [this.customer.ParentLeadId],
			IsVisibleToAll: [this.customer.IsVisibleToAll],
			LeadContacts: this.fb.array([]),
			Customfields: this.fb.array([]),
		});
		setTimeout(() => {
			if (this.customer.LeadContacts.length > 0) {
				for (const contact of this.customer.LeadContacts) {
					if (contact.IsActive) {
						this.LeadContacts.push(
							this.fb.group({
								Id: [contact.Id],
								IsActive: [contact.IsActive],
								Name: [contact.Name],
								ContactNo: [contact.ContactNo],
								Email: [contact.Email],
								Designation: [contact.Designation],
							})
						);
					}
				}
			}
			if (this.customer.Id > 0) {
				let temp = [];
				if (this.customer.CustomFields.length) {
					for (const customField of this.customFields) {
						for (const detailCustomField of this.customer.CustomFields) {
							if (customField.Id === detailCustomField.CustomFieldId) {
								this.Customfields.push(
									this.fb.group({
										CustomFieldId: [detailCustomField.CustomFieldId],
										FieldType: [customField.FieldType],
										Placeholder: [customField.Placeholder],
										Value: [detailCustomField.CustomFieldValue],
										Options: [customField.Options],
										OptionId: [detailCustomField.OptionId],
										IsRemoveImage: [false],
										IsMandatory: [customField.IsMandatory],
									})
								);
								temp.push(customField.Id);
							}
						}
					}
					this.ref.detectChanges();
				}
				for (const customField of this.customFields) {
					if (!temp.includes(customField.Id)) {
						this.Customfields.push(
							this.fb.group({
								CustomFieldId: [customField.Id],
								FieldType: [customField.FieldType],
								Placeholder: [customField.Placeholder],
								Value: [""],
								Options: [customField.Options],
								OptionId: [],
								IsRemoveImage: [false],
							})
						);
					}
				}
				this.ref.detectChanges();
			} else {
				if (this.customFields.length) {
					for (const customField of this.customFields) {
						this.Customfields.push(
							this.fb.group({
								CustomFieldId: [customField.Id],
								FieldType: [customField.FieldType],
								Placeholder: [customField.Placeholder],
								Value: [""],
								Options: [customField.Options],
								OptionId: [customField.OptionId],
								IsMandatory: [customField.IsMandatory],
							})
						);
					}
					this.ref.detectChanges();
				}
			}

			for (let i = 0; i < this.customerForm.controls["Customfields"].value.length; i++) {
				if (this.customerForm.controls["Customfields"].value[i].FieldType == "YesNo" && this.customerForm.controls["Customfields"].value[i].OptionId != null && this.customerForm.controls["Customfields"].value[i].OptionId[0] != 0) {
					(this.customerForm.get("Customfields") as FormArray)["controls"][i].patchValue({
						OptionId: this.customerForm.controls["Customfields"].value[i].OptionId[0],
					});
				}

				if (this.customerForm.controls["Customfields"].value[i].FieldType == "Single" && this.customerForm.controls["Customfields"].value[i].OptionId != null && this.customerForm.controls["Customfields"].value[i].OptionId[0] != 0) {
					(this.customerForm.get("Customfields") as FormArray)["controls"][i].patchValue({
						OptionId: this.customerForm.controls["Customfields"].value[i].OptionId[0],
					});
				}

				if (this.customerForm.controls["Customfields"].value[i].FieldType == "Date" && this.customerForm.controls["Customfields"].value[i].Value) {
					(this.customerForm.get("Customfields") as FormArray)["controls"][i].patchValue({
						Value: new Date(this.customerForm.controls["Customfields"].value[i].Value),
					});
				}
			}
		}, 2000);
	}

	openMap() {
		let address = this.customerForm.controls["Address"].value;
		const dialogRef = this.dialog.open(AddressDialogComponent, {
			width: "550px",
			data: {
				address: address,
				action: "",
			},
		});

		dialogRef.afterClosed().subscribe(result => {
			if (!result)
				return
			this.customerForm.controls['Address'].patchValue(result.res)
			console.log(result);
			
			this.longitude = result.longitude
			this.latitude = result.latitude

		});
	}

	getTitle(): string {
		if (this.customer.Id > 0) {
			this.isCustomer = true;
			return "Edit customer";
		}
		this.isCustomer = false;
		return "New customer";
	}

	isControlInvalid(controlName: string): boolean {
		const control = this.customerForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	prepareCustomer(): any {
		const customValue = this.customerForm.controls["Customfields"].value;
		for (let i = 0; i < customValue.length; i++) {
			let tempArray = [];
			if (typeof customValue[i].OptionId == "number" && customValue[i].OptionId != null) {
				tempArray.push(customValue[i].OptionId);
				customValue[i].OptionId = tempArray;
			}
			if (customValue[i].FieldType == "Image" || customValue[i].FieldType == "CameraImage" || customValue[i].FieldType == "Signature") {
				if (customValue[i].Value.image) {
					Object.assign(customValue[i], { IsRemoveImage: customValue[i].Value.isRemove });
					customValue[i].Value = customValue[i].Value.image;
				} else if (customValue[i].Value) {
					// delete customValue[i].Options;
					// delete customValue[i].OptionId;
					// delete customValue[i].FieldType;
					// delete customValue[i].Placeholder;
					customValue[i].Value = customValue[i].Value;
				}
			}

			if (customValue[i].FieldType == "Date" && customValue[i].Value) {
				customValue[i].Value = this.customDatePipe.transform(customValue[i].Value, 0, "MM/dd/yyyy");
			}

			if (!(customValue[i].FieldType == "Image" || customValue[i].FieldType == "CameraImage" || customValue[i].FieldType == "Signature")) {
				delete customValue[i].IsRemoveImage;
			}
		}

		const controls = this.customerForm.controls;
		const _customer = new CustomerModel();
		_customer.LeadId = this.customer.Id;
		_customer.LeadName = controls['LeadName'].value;
		_customer.RefNo = controls['RefNo'].value;
		_customer.ReferredBy = controls['ReferredBy'].value;
		_customer.PersonName = controls['PersonName'].value;
		_customer.MobileNo = controls['MobileNo'].value;
		_customer.ContactNo = controls['ContactNo'].value;
		_customer.Email = controls['Email'].value;
		_customer.PersonDesignation = controls['PersonDesignation'].value;
		_customer.Address = controls['Address'].value;
		_customer.CountryId = controls['CountryId'].value;
		_customer.StateId = controls['StateId'].value;
		_customer.CityId = controls['CityId'].value;
		_customer.LocalityId = controls['LocalityId'].value;
		_customer.PinCode = controls['PinCode'].value;
		_customer.LeadSourceId = controls['LeadSourceId'].value;
		_customer.LeadStageId = controls['LeadStageId'].value;
		_customer.TerritoryId = controls['TerritoryId'].value;
		_customer.Website = controls['Website'].value;
		_customer.OtherInfo = controls['OtherInfo'].value;
		_customer.LeadTypeId = controls['LeadTypeId'].value;
		_customer.ParentLeadId = controls['ParentLeadId'].value;
		_customer.IsVisibleToAll = controls['IsVisibleToAll'].value;
		_customer.LeadContacts = controls['LeadContacts'].value;
		_customer.CustomFieldValues = controls['Customfields'].value;
		_customer.Latitude = this.latitude 
		_customer.Longitude = this.longitude

		if (controls['FrontView'].value.image) {
			_customer.FrontView = controls['FrontView'].value.image;
		} else {
			_customer.FrontView = "";
		}
		if (controls["BackView"].value.image) {
			_customer.BackView = controls["BackView"].value.image;
		} else {
			_customer.BackView = "";
		}
		if (controls["LeadTags"].value.Tags instanceof Array) {
			_customer.LeadTags = controls["LeadTags"].value.Tags;
		}
		if (_customer.LeadId) {
			_customer.RemoveFrontView = controls["FrontView"].value.isRemove;
			_customer.RemoveBackView = controls["BackView"].value.isRemove;
		}
		return _customer;
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.customerForm.controls;

		if (this.customerForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			const leadContacts = this.LeadContacts.controls;
			for (let i = 0; i < leadContacts.length; i++) {
				const contact = leadContacts[i] as FormGroup;
				const nameControl = contact.get("Name");
				const contactNoControl = contact.get("ContactNo");

				nameControl.markAsTouched();
				contactNoControl.markAsTouched();

				if (nameControl.invalid || contactNoControl.invalid) {
					this.hasFormErrors = true;
				}
			}

			const customFields = this.Customfields.controls;
			for (let i = 0; i < customFields.length; i++) {
				const contact = customFields[i] as FormGroup;
				const contactControls = contact.controls;
				Object.keys(contactControls).forEach((controlName) => {
					const control = contactControls[controlName];
					control.markAsTouched();

					if (control.invalid) {
						this.hasFormErrors = true;
					}
				});
			}

			if (this.hasFormErrors) {
				return;
			}
			return;
		}

		const leadContacts = this.LeadContacts.controls;
		for (let i = 0; i < leadContacts.length; i++) {
			const contact = leadContacts[i] as FormGroup;
			const contactControls = contact.controls;

			Object.keys(contactControls).forEach((controlName) => {
				const control = contactControls[controlName];
				control.markAsTouched();

				if (control.invalid) {
					this.hasFormErrors = true;
				}
			});
		}

		if (this.hasFormErrors) {
			return;
		}

		const editedCustomer = this.prepareCustomer();
		if (editedCustomer.ReferredBy == null) {
			editedCustomer.ReferredBy = 0;
		}
		if (this.frontViewRemove) {
			Object.assign(editedCustomer, { RemoveFrontView: true });
		}
		if (this.backViewRemove) {
			Object.assign(editedCustomer, { RemoveBackView: true });
		}
		if (editedCustomer.LeadId > 0) {
			this.updateCustomer(editedCustomer);
		} else {
			this.createCustomer(editedCustomer);
		}
	}

	makeImageValueBlank(value) {
		if (value == 1) {
			this.customer.FrontView = "";
			this.frontViewRemove = true;
		} else if (value == 2) {
			this.customer.BackView = "";
			this.backViewRemove = true;
		}
	}

	updateCustomer(_customer: CustomerModel) {
		this.viewLoading = true;
		this.customersService
			.updateCustomer(_customer)
			.pipe(
				map((res) => {
					if (res.Status == 200) {
						this.dialogRef.close({ _customer, isEdit: true });
						this.customersService.load.next(true);
					}
					this.viewLoading = false;
					this.ref.detectChanges();
				})
			)
			.subscribe();
	}

	createCustomer(_customer: CustomerModel) {
		this.viewLoading = true;
		if (_customer.ReferredBy == "" || _customer.ReferredBy != "") {
			this.customersService
				.addCustomer(_customer)
				.pipe(
					map((res) => {
						if (res.Status == 200) {
							this.dialogRef.close({ _customer, isEdit: false });
							this.customerForm.controls["MobileNo"].setErrors({ incorrect: false });
							this.customerForm.controls["Email"].setErrors({ incorrect: false });
						} else {
							if (res.Message.includes("Mobile")) {
								this.sameMobileNo = res.Message;
								this.customerForm.controls["MobileNo"].setErrors({ incorrect: true });
							} else if (res.Message.includes("email")) {
								this.sameEmail = res.Message;
								this.customerForm.controls["Email"].setErrors({ incorrect: true });
							}
						}
						this.viewLoading = false;
						this.ref.detectChanges();
					})
				)
				.subscribe();
		}
	}
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	getCountryList() {
		this.stateList.length = 0;
		this.cityList.length = 0;
		this.localityList.length = 0;
		this.customerForm.get("StateId").reset(0);
		this.customerForm.get("CityId").reset(0);
		this.customerForm.get("LocalityId").reset(0);
		this.authService
			.GetCountry()
			.pipe(
				map((res) => {
					if (res) {
						this.countryList = res;
						this.filterArrayCountry = res;
						if (this.customer.CountryId > 0) {
							const toSelect = this.countryList.find((c) => c.CountryId == this.customer.CountryId);
							this.customerForm.get("CountryId").setValue(toSelect.CountryId);
							this.getStateList(toSelect.CountryId);
						}
					}
				})
			)
			.subscribe();
	}

	getStateList(CountryId) {
		this.customerForm.get("StateId").reset(0);
		this.stateList.length = 0;
		this.cityList.length = 0;
		this.localityList.length = 0;

		this.customerForm.get("CityId").reset(0);
		this.customerForm.get("LocalityId").reset(0);
		this.addressService
			.getState(CountryId)
			.pipe(
				map((res) => {
					if (res) {
						this.stateList = res;
						this.filterArrayState = res;
						this.filterArrayState = res;
						if (this.customer.StateId > 0) {
							const toSelect = this.stateList.find((c) => c.StateId == this.customer.StateId);
							this.customerForm.get("StateId").setValue(toSelect.StateId);
							this.getCityList(toSelect.StateId);
						}
					}
				})
			)
			.subscribe();
	}

	getCityList(StateId) {
		
		this.customerForm.get("CityId").reset(0);
		this.customerForm.get("LocalityId").reset(0);
		this.cityList.length = 0;
		this.localityList.length = 0;

		this.addressService
			.getCity(StateId)
			.pipe(
				map((res) => {
					if (res) {
						this.cityList = res;
						this.filterArrayCity = res;
						if (this.customer.CityId > 0) {
							const toSelect = this.cityList.find((c) => c.CityId == this.customer.CityId);
							this.customerForm.get("CityId").setValue(toSelect.CityId);
							this.getLocalityList(toSelect.CityId);
						}
					}
				})
			)
			.subscribe();
	}
	getReferalList() {
		this.customersService
			.getreferralList()
			.pipe(
				map((res: any) => {
					this.referralList = res.data.results;
					this.businessData = res.data.results;
				})
			)
			.subscribe();
	}

	getLocalityList(CityId) {
		this.customerForm.get("LocalityId").reset(0);

		this.localityList.length = 0;

		this.addressService
			.getLocality(CityId)
			.pipe(
				map((res) => {
					if (res) {
						this.localityList = res;
						this.filterArrayLocality = res;
						if (this.customer.LocalityId > 0) {
							const toSelect = this.localityList.find((c) => c.LocalityId == this.customer.LocalityId);
							this.customerForm.get("LocalityId").setValue(toSelect.LocalityId);
						}
					}
				})
			)
			.subscribe();
	}

	filterValueCountry(searchCountry) {
		if (searchCountry != "") {
			this.filterArrayCountry = this.countryList.filter((option) => {
				return option.Name.toLowerCase().includes(searchCountry.toLowerCase());
			});
		} else {
			this.filterArrayCountry = this.countryList;
		}
	}
	filterValueState(searchState) {
		if (searchState != "") {
			this.filterArrayState = this.stateList.filter((option) => {
				return option.Name.toLowerCase().includes(searchState.toLowerCase());
			});
		} else {
			this.filterArrayState = this.stateList;
		}
	}
	filterValueCity(searchCity) {
		if (searchCity != "") {
			this.filterArrayCity = this.cityList.filter((option) => {
				return option.Name.toLowerCase().includes(searchCity.toLowerCase());
			});
		} else {
			this.filterArrayCity = this.cityList;
		}
	}
	filterValueLocality(searchLocality) {
		if (searchLocality != "") {
			this.filterArrayLocality = this.localityList.filter((option) => {
				return option.LocalityName.toLowerCase().includes(searchLocality.toLowerCase());
			});
		} else {
			this.filterArrayLocality = this.localityList;
		}
	}

	filterValueDistributor(searchDistributor) {
		if (searchDistributor != "") {
			this.filterparentCustomer = this.parentCustomerList.filter((option) => {
				return option.LeadName.toLowerCase().includes(searchDistributor.toLowerCase());
			});
		} else {
			this.filterparentCustomer = this.parentCustomerList;
		}
	}
	getSourceList() {
		this.customersService.getSource().subscribe((res) => (this.sourceList = res));
	}

	getStageList() {
		this.customersService.getStage().subscribe((res) => (this.stageList = res));
	}

	getByRefferd() {}

	getTypeList() {
		this.customersService
			.getType()
			.pipe(
				map((res) => {
					this.typeList = res;
					if (this.customer.LeadTypeId > 0) {
						const toSelect = this.typeList.find((c) => c.Id == this.customer.LeadTypeId);
						this.customerForm.get("LeadTypeId").setValue(toSelect.Id);
						this.getParentCustomerList(toSelect.Id);
					}
				})
			)
			.subscribe();
	}

	getParentCustomerList(customerTypeId) {
		this.customersService
			.getParentCustomer(customerTypeId)
			.pipe(
				map((res) => {
					this.parentCustomerList = res;
					this.filterparentCustomer = this.parentCustomerList;
					if (this.customer.ParentLeadId > 0) {
						const toSelect = this.parentCustomerList.find((c) => c.Id == this.customer.ParentLeadId);
						this.customerForm.get("ParentLeadId").setValue(toSelect.Id);
					}
					this.ref.detectChanges();
				})
			)
			.subscribe();
	}

	getTerritoryList() {
		this.customersService.getTerritory().subscribe((res) => (this.territoryList = res));
	}

	getTagList() {
		this.customersService.getTag().subscribe((res) => (this.tagList = res));
	}

	getDesignationList() {
		this.customersService.getDesignation().subscribe((res) => {
			this.designationList = res;
		});
	}

	getCustomFields() {
		this.customersService.getCustomFields().subscribe((res) => {
			this.customFields = res;
			console.log(this.customFields)
			this.ref.detectChanges();
			res.forEach(field => {
				  this.filteredOptionSubject[field.Id] = new ReplaySubject<any[]>(1);
				  this.filteredOptionSubject[field.Id].next(field.Options.slice());
				  this.filteredCustomOptions[field.Id] = field.Options
			});

			console.log(this.filteredOptionSubject);
			
		});
		
	}

	searchManager(filterReferBy) {
		if (filterReferBy != "") {
			this.businessData = this.referralList.filter((option) => {
				return option.userName.toLowerCase().includes(filterReferBy.toLowerCase());
			});
		} else {
			this.businessData = this.referralList;
		}
	}

	get LeadContacts() {
		return this.customerForm.get("LeadContacts") as FormArray;
	}

	get Customfields() {
		return this.customerForm.get("Customfields") as FormArray;
	}

	addMoreContact() {
		this.LeadContacts.push(
			this.fb.group({
				Name: ["", Validators.compose([Validators.required, Validators.maxLength(200)])],
				ContactNo: ["", Validators.compose([Validators.required, Validators.maxLength(15)])],
				Email: ["", Validators.compose([Validators.maxLength(200)])],
				Designation: ["", Validators.compose([Validators.maxLength(200)])],
			})
		);
	}

	removeContact(contact, index: number) {
		if (contact.value.Id) {
			contact.value.IsActive = false;
		} else {
			this.LeadContacts.removeAt(0);
		}
	}

	remove(customId) {
		const customValue = this.customerForm.controls["Customfields"].value;
		for (let i = 0; i < this.customer.CustomFields.length; i++) {
			if (this.customer.CustomFields[i].CustomFieldId == customId) {
				this.customer.CustomFields[i].CustomFieldValue = "";
			}
		}
		for (let i = 0; i < customValue.length; i++) {
			if (customValue[i].CustomFieldId == customId) {
				(this.customerForm.get("Customfields") as FormArray)["controls"][i].patchValue({
					CustomFieldId: customId,
					Value: "",
					Options: [],
					OptionId: [0],
					IsRemoveImage: true,
				});
			}
		}
		this.ref.detectChanges();
	}
	getReferralData() {
		var referralData = {
			from: 1,
			to: 10,
			search: "",
			statusIds: 2,
			fromDate: "",
			toDate: "",
		};
		this.myreferralsService
			.getMyReferral(referralData)
			.pipe(
				map((myreferral) => {
					console.log(myreferral);
					this.referralList = myreferral.data.results;
					this.businessData = myreferral.data.results;
					console.log(this.businessData);
				})
			)
			.subscribe();
	}
	//Designation person filter

	designationFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.designationList.filter((res) => res.Title.toLowerCase().includes(filterValue));
	}

	closeModal() {
		this.dialogRef.close();
	}
}
