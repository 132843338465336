// Angular
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
// RxJS
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, map, finalize } from 'rxjs/operators';

import { CallLogModel, CustomersService } from '../../../../../../../core/crm/customers';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormDialogComponent } from './form-dialog/form-dialog.component'
// Modal
import { MatDialog } from '@angular/material/dialog';

// Component
import { DialogComponent } from './dialog/dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AddOpportunitiesDialogComponent } from '../../../../opportunities/add-opportunities/add-opportunities.dialog.component';
import { Router } from '@angular/router';
import { toDate } from 'date-fns';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'kt-timeline',
	templateUrl: './timeline.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: ['.kt-transaction-content{height: 55vh; overflow-y: auto; overflow-x: hidden; padding-right: 10px;}',
		'.timelineStatucIcon{display: inline-block; width: 20px; height: 20px; border-radius: 50%; text-align: center; color: white; padding: 2px;}',
		'.searchBar{position:absolute;right:0}']
})
export class TimelineComponent implements OnInit {
	@Input() loadingSubject = new BehaviorSubject<boolean>(false);
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;
	@ViewChild('transactionContent', { static: true }) private transactionContentContainer: ElementRef;
	@Input() customerId: number;
	@Input() statusList
	@Input() referralList;
	@Input() userName;
	@Input() referByCode;
	@Input() opportunityId;
	@Input() isCheckBox:boolean ;
	@Input() opportunityName;
	@Input() opportunityNumber;
	customerStatusList;
	comment = new FormControl('', Validators.compose([Validators.pattern(/(\W{20,}|\x20{1}|[^\s]{1})/g), Validators.maxLength(2000)]));
	customerTransactionList: any = [];
	formsection: any = []
	customerMeetingObjectiveList: any = []
	formdata :any=[]
	add: any = []
	customer = {
		From: 0,
		To: 50,
		LeadId: null,
		Text: '',
		StatusIds: '',
		IsVisitForm: '',
		IsCheckIn: '',
		IsProductsAvailable: '',
		OpportunityId:'',
		FromDate:null,
		ToDate:null
	}
	loadingTransaction:boolean = false;
	isCheckBoxSelect:boolean = false;
	commentInvalid:boolean = false;
	callLogForm: FormGroup;
	callObjectiveShowError: boolean = false;
	private subscriptions: Subscription[] = [];
	buttonDisabled = false;
	clearData: boolean = false;
	viewLoading: boolean = false;
	isEnterComment: boolean = false;
	isApiCall: boolean = false;
	emptyArrayName: string = 'Please Wait...';

	constructor(
		private ref: ChangeDetectorRef,
		private customersService: CustomersService,
		private fb: FormBuilder,
		private toastrService: ToastrService,
		private translate: TranslateService,
		private router: Router,
		private datepipe: DatePipe,
		public dialog: MatDialog) { }

	ngOnInit() {
		this.getCustomerStatus();
		this.initCallLogForm();
		this.getMeetingObjective();
	}

	initCallLogForm(){
		this.callLogForm = this.fb.group({
			StatusId: [""],
			VisitPurposeIds: [""],
		});
	}

	ngAfterViewInit() {
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(50),
			distinctUntilChanged(),
			tap(() => {
				this.getCustomerTransaction();
			})
		)
			.subscribe();
		this.subscriptions.push(searchSubscription);

		if (this.isCheckBox == true) {
			this.callLogForm.controls["StatusId"].setValidators([Validators.required]);
			this.callLogForm.controls["StatusId"].updateValueAndValidity();
			// this.callLogForm.controls["VisitPurposeIds"].setValidators([Validators.required]);
			// this.callLogForm.controls["VisitPurposeIds"].updateValueAndValidity();
		} else {
			this.callLogForm.controls["StatusId"].clearValidators();
			this.callLogForm.controls["StatusId"].updateValueAndValidity();
			// this.callLogForm.controls["VisitPurposeIds"].clearValidators();
			// this.callLogForm.controls["VisitPurposeIds"].updateValueAndValidity();
		}
	}

	ngAfterViewChecked() {
		this.scrollToBottom();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.customerId && this.customerId > 0 && this.customerTransactionList) {
			this.getCustomerTransaction();
		}
	}

	getCustomerStatus() {
		this.customersService.getCustomerStatus()
			.pipe(
				map(res => {
					if (res != '') {
						this.customerStatusList = res;
						this.ref.detectChanges();
					}
				})
			)
			.subscribe();
	}

	getCustomerTransaction() {
		this.customerTransactionList = [];
		this.loadingTransaction = true;
		// this.ref.detectChanges();
			this.customer.LeadId = this.customerId;
			this.customer.OpportunityId = this.opportunityId ? this.opportunityId : this.customer.OpportunityId,
			this.customer.Text = this.searchInput.nativeElement.value;
			this.customersService.customerTransaction(this.customer)
				.pipe(
					map(res => {
						if (res) {
							this.customerTransactionList = res.reverse();
							setTimeout(() => {
								var ObjectiveArr = [];
								for (const transaction of this.customerTransactionList) {
									if (transaction.Objective) {
										ObjectiveArr = transaction.Objective.split(",");
									}
									transaction.ObjectiveArr = ObjectiveArr;
								}
								this.scrollToBottom();
								this.ref.detectChanges();

							});
						}else{
							this.customerTransactionList = [];
							this.emptyArrayName='No Records Found';
						}
						// this.ref.detectChanges();
					}),
					finalize(() => {
						this.loadingTransaction = false; // Set loading to false when the request completes
						  this.ref.detectChanges();
					})
				)
			.subscribe();
	}

	getTransactionObjective(objective: any) {
		if (!objective) {
			return []
		}

		return objective.split(',');
	}



	onEnter() {
		debugger

		if (this.comment.value && this.isEnterComment) {
			const _customerComment = {
				LeadId: this.customerId,
				Note: (this.opportunityName) ? (this.opportunityName + " :: " + this.comment.value) : (this.comment.value) ? this.comment.value : '',
				OpportunityId: this.opportunityId,
			}
			this.isApiCall=true;
			this.customersService.commentOnCustomerTransactions(_customerComment)
				.pipe(
					map(res => {
						this.getCustomerTransaction();
						this.comment.patchValue('');
						this.ref.detectChanges();
						this.scrollToBottom();
						this.isApiCall=false;
						this.isEnterComment = false;
					})
				)
				.subscribe();
		}
		else{
			this.commentInvalid = true;
		}
	}

	scrollToBottom() {
		try {
			this.transactionContentContainer.nativeElement.scrollTop = this.transactionContentContainer.nativeElement.scrollHeight;
		} catch (err) { }
	}

	applyFilter(data) {
		console.log(data);

		this.customer.StatusIds = data.filterData.StatusIds;
		this.customer.OpportunityId = data.filterData.OpportunityId;

		if (data.filterData.FromDate && data.filterData.ToDate) {
			this.customer.FromDate = this.datepipe.transform(data.filterData.FromDate, "yyyy-MM-dd 12:00:00") + "AM";
			this.customer.ToDate = this.datepipe.transform(data.filterData.ToDate, "yyyy-MM-dd 11:59:59") + "PM";
		} else {
			this.customer.FromDate = "";
			this.customer.ToDate = "";
		}

		this.getCustomerTransaction();
	}

	openInDialog(dataFrom, transaction) {
		this.dialog.open(DialogComponent, {data: {transaction: transaction,showWindow: dataFrom},width:'800px'});
	}
	formdetail(data) {
		this.formsection = data.FormSection;
        this.formdata = data.FormSection[0].Controls;
		 this.dialog.open(FormDialogComponent, {
			data: { data:this.formdata ,section :this.formsection }, width: '500px', height: 'auto'
		  });


	}

	getDetails(transaction) {
		this.customersService.getdetails(transaction)
			.pipe(map(res => {
				if (res.Status == 200) {
					this.add = res.data
					this.formdetail(this.add)
				}
			})).subscribe()
	}


	isCheckBoxSelectFn(event:boolean){
		this.isCheckBoxSelect=event;

		if(this.isCheckBoxSelect == true)
			if(this.callLogForm.get('StatusId').value)
				this.isEnterComment=true;
			else
				this.isEnterComment=false;
		else
			this.isEnterComment=true;
	}

	getMeetingObjective() {
		this.customersService
			.getMeetingObjective()
			.pipe(
				map((res) => {
					this.customerMeetingObjectiveList = res;
					this.ref.detectChanges();
				})
			)
			.subscribe();
	}


		addCallLog() {
			debugger
			console.log(this.callLogForm.get('StatusId').value);

			this.buttonDisabled = true;
			// this.clearData = false;
			const controls = this.callLogForm.controls;

			if(this.callLogForm.get('StatusId').value && this.comment.value){
				this.isEnterComment=true;
				// this.callLogForm.controls["StatusId"].clearValidators();
				// this.callLogForm.controls["StatusId"].updateValueAndValidity();
			}

			else{
				this.isEnterComment=false;
				// this.callLogForm.controls["StatusId"].setValidators([Validators.required]);
				// this.callLogForm.controls["StatusId"].updateValueAndValidity();
				this.toastrService.error("Status is required");
				if(!this.comment.value)
				this.commentInvalid = true;
				return;
			}


			// if (this.callLogForm.invalid) {
			// 	Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			// 	return;
			// }
			this.viewLoading = true;
			const resControls = this.callLogForm.controls;
			const _callLog = new CallLogModel();

			// if (this.callLogForm.invalid) {
			// 	Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			// 	this.viewLoading = false;
			// 	return;
			// }


			_callLog.StatusId = (resControls["StatusId"].value) ? resControls["StatusId"].value.selectInputValue : '';
			_callLog.VisitPurposeIds = (resControls["VisitPurposeIds"].value) ? resControls["VisitPurposeIds"].value.multipleCheckInputValue : '';
			_callLog.Note = (this.opportunityName) ? (this.opportunityName + " :: " + this.comment.value) : (this.comment.value) ? this.comment.value : '';
			_callLog.OpportunityId = this.opportunityId,
			_callLog.LeadId= this.customerId,
			_callLog.IsCallLog = true;

			// if(){
			// 	this.commentInvalid = true;
			// 	return;
			// }

			this.isApiCall=true;
			this.customersService
				.addCallLog(_callLog)
				.pipe(
					map((res) => {
						if (res.Status == 200) {
							setTimeout(() => {
								this.toastrService.success("Call Log Added Successfully");
								// this.reloadBoolean.emit(this.reload);
								this.clearData = true;
								this.closeDropdown();
								this.initCallLogForm();
								this.callLogForm.reset();
								this.buttonDisabled = true;

								this.getCustomerTransaction();
								this.comment.patchValue('');
								this.ref.detectChanges();
								this.scrollToBottom();

								this.isEnterComment=false;
								this.commentInvalid = false;
								this.isApiCall=false;

							}, 2000);
						} else if (res.Status == 417) {
							this.buttonDisabled = false;
							this.toastrService.error("Something Went Wrong");
								this.isApiCall=false;
						} else {
							this.buttonDisabled = false;
							this.viewLoading = false;
							this.toastrService.error("Something Went Wrong");
								this.isApiCall=false;
						}
					})
				)
				.subscribe();
		}

		closeDropdown() {
			this.callLogForm.reset();
			this.viewLoading = false;
			this.ref.detectChanges();
		}

		enterCommentFn(event: Event) {
			const textarea = event.target as HTMLTextAreaElement;
			if(textarea.value){
				if(this.isCheckBoxSelect == true)
					if(this.callLogForm.get('StatusId').value)
						this.isEnterComment=true;
					else
						this.isEnterComment=false;
				else
					this.isEnterComment=true;
				this.commentInvalid = false;
			}
			else{
				this.isEnterComment=false;
				this.commentInvalid = true;
			}

		}
		selectStatusId(event:Event)
		{
			console.log(event);
			if(event && this.comment.value)
				this.isEnterComment=true;
			else
				this.isEnterComment=false;

		}

		editOpportunities(item:any , action) {
			console.log(item);
			// opportunityNumber
			if(item.OpportunityId){
				let newWidth = '800px';
				let params = {
				opportunities: item,
				 id:item.OpportunityId,
				 opportunityNo: this.opportunityNumber,//item.OpportunityNumber,
				 action: action,
				 opportunityView:'Opportunity'
				}

				const dialogRef = this.dialog.open(AddOpportunitiesDialogComponent, {
				  data: params, width: newWidth
				});
				dialogRef.afterClosed().subscribe(res => {
				  if (!res) {
					return;
				  }
				});
			}

		  }
}
