// Angular
import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
// RxJS
import { Observable, Subscription } from 'rxjs';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';

@Component({
	selector: 'kt-customer-timeline',
	templateUrl: './customer-timeline.component.html',
	styles: ['.mat-tab-label{height: 30px !important; min-width: 120px !important}'],
	encapsulation: ViewEncapsulation.None
})
export class CustomerTimelineComponent implements OnInit, OnDestroy {
	selectedTab: number = 0;
	@Input() customerId: number;
	@Input() statusList;
	@Input() userName;
	@Input() referralList;
	@Input() referByCode;
	@Input() opportunityId;
	@Input() isCheckBox;
	@Input() opportunityName;
	@Input() opportunityNumber;
	loading$: Observable<boolean>;
	routeId: number;
	customerStatusList;
	private subscriptions: Subscription[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private layoutConfigService: LayoutConfigService,
	) { }

	ngOnInit() {
		//read routeId parameter from route and set routeId if this page visited from roaster Plan page
		this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
			this.routeId = parseInt(params.get('id'));
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		this.selectedTab = 0;
		this.routeId = this.customerId;
		this.customerStatusList = this.statusList;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

	/**
	 * Redirect to list
	 *
	 */
	goBackWithId() {
		const url = `${this.layoutConfigService.getCurrentMainRoute()}/user-management/users`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

}
