// Partials for CRUD
import {
	ActionNotificationComponent,
	ConfirmationDialogComponent,
	DeleteEntityDialogComponent,
	DuplicateEntityDialogComponent,
	FetchEntityDialogComponent,
	FileUploadDialogComponent,
	MoveReasonDialogComponent,
	MultipleCheckListDialogComponent,
	SelectListDialogComponent,
	SingleCheckListDialogComponent,
	UpdateStatusDialogComponent,	
} from '../../../../views/partials/content/crud';
import { MatDialog, MatSnackBar } from '@angular/material';

// Angular
import { Injectable } from '@angular/core';

export enum MessageType {
	Create,
	Read,
	Update,
	Delete
}

@Injectable()
export class LayoutUtilsService {
	
	/**
	 * Service constructor
	 *
	 * @param snackBar: MatSnackBar
	 * @param dialog: MatDialog
	 */
	constructor(private snackBar: MatSnackBar,
		private dialog: MatDialog) { }

	/**
	 * Showing (Mat-Snackbar) Notification
	 *
	 * @param message: string
	 * @param type: MessageType
	 * @param duration: number
	 * @param showCloseButton: boolean
	 * @param showUndoButton: boolean
	 * @param undoButtonDuration: number
	 * @param verticalPosition: 'top' | 'bottom' = 'top'
	 */
	showActionNotification(
		_message: string,
		_type: MessageType = MessageType.Create,
		_duration: number = 10000,
		_showCloseButton: boolean = true,
		_showUndoButton: boolean = true,
		_undoButtonDuration: number = 3000,
		_verticalPosition: 'top' | 'bottom' = 'bottom'
	) {
		const _data = {
			message: _message,
			snackBar: this.snackBar,
			showCloseButton: _showCloseButton,
			showUndoButton: _showUndoButton,
			undoButtonDuration: _undoButtonDuration,
			verticalPosition: _verticalPosition,
			type: _type,
			action: 'Undo'
		};
		return this.snackBar.openFromComponent(ActionNotificationComponent, {
			duration: _duration,
			data: _data,
			verticalPosition: _verticalPosition
		});
	}

	/**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
	deleteElement(title: string = '', description: string = '', waitDesciption: string = '') {
		return this.dialog.open(DeleteEntityDialogComponent, { data: { title, description, waitDesciption },width: '440px'
		});
	}

			/**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
		duplicateElement(title: string = '', description: string = '', waitDesciption: string = '', _counts : string='') {
			return this.dialog.open(DuplicateEntityDialogComponent, { data: {title, description, waitDesciption , _counts },width: '440px'
			});
		}

	/**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
	 moveElement(title: string = '', description: string = '', waitDesciption: string = '') {
		return this.dialog.open(MoveReasonDialogComponent, {
			data: { title, description, waitDesciption },
			width: '440px'
		});
	}
	/**
	 * Showing Confirmation (Mat-Dialog) before Entity Updating
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * 	 * @param waitDesciption: string
	 */
	confirmElement(title: string = '', description: string = '', waitDesciption: string = '', btnName: string = '') {
		return this.dialog.open(ConfirmationDialogComponent, {
			data: { title, description, waitDesciption, btnName },
			width: '440px'
		});
	}

	/**
	 * Showing Fetching Window(Mat-Dialog)
	 *
	 * @param _data: any
	 */
	fetchElements(_data) {
		return this.dialog.open(FetchEntityDialogComponent, {
			data: _data,
			width: '400px'
		});
	}

	/**
	 * Showing Update Status for Entites Window
	 *
	 * @param title: string
	 * @param statuses: string[]
	 * @param messages: string[]
	 */
	updateStatusForEntities(title, statuses, messages) {
		return this.dialog.open(UpdateStatusDialogComponent, {
			data: { title, statuses, messages },
			width: '480px'
		});
	}

	/**
	 * Showing File Upload
	 *
	 * @param title: stirng
	 * @param waitDesciption: string
	 */
	fileUploadDialog(title: string = '', waitDesciption: string = '', from?: string) {
		return this.dialog.open(FileUploadDialogComponent, {
			data: { title, waitDesciption, from },
			width: '440px'
		});
	}

	/**
	 * Showing Single Check List for Entites Window
	 *
	 * @param title: string
	 * @param list: string[]
	 * @param submitButtonText: string
	 */
	singleCheckListDialog(title, list, submitButtonText, text, value) {
		return this.dialog.open(SingleCheckListDialogComponent, {
			data: { title, list, submitButtonText, text, value },
			width: '370px'
		});
	}

	/**
	 * Showing Multiple Check List for Entites Window
	 *
	 * @param title: string
	 * @param list: string[]
	 * @param submitButtonText: string
	 */
	multipleCheckListDialog(title, list, submitButtonText) {
		return this.dialog.open(MultipleCheckListDialogComponent, {
			data: { title, list, submitButtonText },
			width: '370px'
		});
	}

	/**
	 * Showing Select List for Entites Window
	 *
	 * @param title: string
	 * @param list: string[]
	 * @param submitButtonText: string
	 */
	selectListDialog(title, list) {
		return this.dialog.open(SelectListDialogComponent, {
			data: { title, list },
			width: '250px'
		});
	}

}