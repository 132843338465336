// Angular
import { Component, EventEmitter, Output, Input, ChangeDetectorRef, ViewChild, ElementRef, OnInit, OnChanges, HostListener, OnDestroy } from "@angular/core";
import { NgbDropdownConfig, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, Validators, FormBuilder, FormArray } from "@angular/forms";
import { map } from "rxjs/operators";
// Translate Module
import { TranslateService } from "@ngx-translate/core";
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from "../../../../core/_base/crud";
// Services and Models
import { CustomersService, CallLogModel, OpportunityModel, QuickTaskModel, AppointmentModel } from "../../../../core/crm/customers";
import { CustomDatePipe } from "../../../../core/_base/layout/index";
// Toastr
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { User, AuthService } from "../../../../core/auth";
import { SelectListInputComponent } from "../../../partials/content/crud/select-list/select-list-input/select-list-input.component";
import { CrmSettingService } from "../../../../core/crm/crm-setting/crm-setting.service";

@Component({
	selector: "kt-customer-action",
	templateUrl: "./customer-action.component.html",
	styleUrls: ["./customer-action.component.scss"],
	providers: [NgbDropdownConfig, SelectListInputComponent],
})
export class CustomerActionComponent implements OnInit, OnDestroy {
	@ViewChild("customerActionDropdown1", { static: true }) dropdown1: NgbDropdown;
	@ViewChild("customerActionDropdown2", { static: true }) dropdown2: NgbDropdown;
	@ViewChild("customerActionDropdown3", { static: true }) dropdown3: NgbDropdown;
	@ViewChild("customerActionDropdown4", { static: true }) dropdown4: NgbDropdown;

	@Input() actionName: string;
	@Input() title: string;
	@Input() customer: any;
	@Input() id: any;
	@Input() customerStatusList: any = [];
	@Input() customerMeetingObjectiveList: any = [];
	@Input() opportunityStageList: any = [];
	@Input() isLostReasonRequired: any = [];
	@Input() reasonList: any = [];
	@Input() teamList: any = [];
	@Input() tagList: any = [];
	@Input() customerActionPointList: any = [];
	@Input() selectedAction: number = 0;
	@Output() reloadBoolean: EventEmitter<Boolean> = new EventEmitter();
	@Output() clickedAction = new EventEmitter();
	@Input() callObjective: boolean;
	reload = true;
	buttonDisabled:boolean = false;
	clearData: boolean = false;
	subscriptions: Subscription[] = [];
	appointmentDateList: any = [];
	callLogForm: FormGroup;
	appointmentForm: FormGroup;
	opportunityForm: FormGroup;
	quickTaskForm: FormGroup;
	customerValue: any;
	currentUser: User;
	_userId: number = 0;
	viewLoading: boolean = false;
	tempStatusId = 0;
	tempVisitPurposeIds: any = [];
	selectedTimePicker;
	outputTime: any = this.customDatePipe.transform(new Date(), 0, "hh:mm a");
	showPicker: boolean = true;
	errorMessage: string;
	current = new Date();
	lostReasonList: any;
	reasonFilter: any;
	selectedValue: any;
	index: number = -1;
	contactsList = [];
	customerId: any;
	callObjectiveShowError: any;
	isTouched: boolean = false;
	data: any;

	constructor(private fb: FormBuilder, private config: NgbDropdownConfig, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private customersService: CustomersService, private toastrService: ToastrService, private authService: AuthService, private ref: ChangeDetectorRef, private customDatePipe: CustomDatePipe, private checkListComponent: SelectListInputComponent, private eleRef: ElementRef, private crmSettingService: CrmSettingService) {
		this.subscriptions.push(
			this.authService.getCurrentUser().subscribe((x) => {
				this.currentUser = x;
			})
		);
		// customize default values of dropdowns used by this component tree
	}

	ngOnInit() {
		this.initCallLogForm();
		this.initAppointmentForm();
		this.initQuickTaskForm();
		this.initOpportunityForm();
		this.reasonFilter = this.reasonList;
		this.crmSettingService.callLogSettingsSubject.subscribe((res) => {
			this.data = res;
			if (res.callObjective) {
				this.callLogForm.controls["VisitPurposeIds"].setValidators([Validators.required]);
				this.callLogForm.controls["VisitPurposeIds"].updateValueAndValidity();
				this.callObjectiveShowError = true;
			} else {
				this.callObjectiveShowError = false;
			}
			if (res.callNote) {
				this.callLogForm.controls["Note"].setValidators([Validators.required]);
				this.callLogForm.controls["Note"].updateValueAndValidity();
			} else {
				this.callLogForm.controls["Note"].clearValidators();
				this.callLogForm.controls["Note"].updateValueAndValidity();
			}
		});
	}

	get controls() {
		return this.callLogForm.controls;
	}

	ngOnDestroy() {
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	ngOnChanges() {
		if (this.selectedAction == 1) {
			this.dropdown2.close();
			this.dropdown3.close();
			this.dropdown4.close();
			this.callLogForm.reset();
			this.appointmentForm.reset();
			this.opportunityForm.reset();
			this.quickTaskForm.reset();
			this.appointmentDateList = [];
			this.viewLoading = false;
			this.ref.detectChanges();
		}
		if (this.selectedAction == 2) {
			this.dropdown1.close();
			this.dropdown3.close();
			this.dropdown4.close();
			this.callLogForm.reset();
			this.appointmentForm.reset();
			this.opportunityForm.reset();
			this.quickTaskForm.reset();
			this.appointmentDateList = [];
			this.viewLoading = false;
			this.ref.detectChanges();
		}
		if (this.selectedAction == 3) {
			this.dropdown1.close();
			this.dropdown2.close();
			this.dropdown4.close();
			this.callLogForm.reset();
			this.appointmentForm.reset();
			this.opportunityForm.reset();
			this.quickTaskForm.reset();
			this.appointmentDateList = [];
			this.viewLoading = false;
			this.ref.detectChanges();
		}
		if (this.selectedAction == 4) {
			this.dropdown1.close();
			this.dropdown2.close();
			this.dropdown3.close();
			this.callLogForm.reset();
			this.appointmentForm.reset();
			this.opportunityForm.reset();
			this.quickTaskForm.reset();
			this.appointmentDateList = [];
			this.viewLoading = false;
			this.ref.detectChanges();
		}
	}

	stageSelect(selectedValue) {
		this.selectedValue = selectedValue;
	}
	searchManager(searchText, text) {
		if (searchText != "") {
			this.reasonFilter = this.reasonList.filter((item) => {
				if (item.Reason.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
					return true;
				}
				return false;
			});
		} else {
			this.reasonFilter = this.reasonList;
		}
	}
	initCallLogForm() {
		this.callLogForm = this.fb.group({
			LeadId: [this.customer.Id],
			StatusId: [""],
			StatusName: [""],
			Note: [""],
			VisitPurposeIds: [""],
			VisitPurposeName: [""],
			IsCallLog: [true],
			ProductIds: [""],
		});
		this.callLogForm.controls.VisitPurposeIds.valueChanges.subscribe((res) => {
			if (res && res["multipleCheckInputValue"]) {
				if (res["multipleCheckInputValue"][0]) {
					return;
				} else {
					this.callLogForm.controls.VisitPurposeIds.setValue("");
					this.callLogForm.updateValueAndValidity();
				}
			}
		});
	}

	initAppointmentForm() {
		this.appointmentForm = this.fb.group({
			LeadId: [this.customer.Id],
			Date: ["", Validators.required],
			AssignedToUserId: [""],
		});
	}

	initOpportunityForm() {
		if (this.isLostReasonRequired.IsReasonRequired) {
			this.opportunityForm = this.fb.group({
				LeadId: [this.customer.Id],
				OpportunityName: ["", Validators.compose([Validators.required])],
				OpportunityStatusId: ["", Validators.compose([Validators.required])],
				AssignedToUserId: [""],
				Value: ["", Validators.compose([Validators.required])],
				CloseDate: ["", Validators.compose([Validators.required])],
				OpportunityTags: [""],
				reason: ["", Validators.required],
			});
		} else {
			this.opportunityForm = this.fb.group({
				LeadId: [this.customer.Id],
				OpportunityName: ["", Validators.compose([Validators.required])],
				OpportunityStatusId: ["", Validators.compose([Validators.required])],
				AssignedToUserId: [""],
				Value: ["", Validators.compose([Validators.required])],
				CloseDate: ["", Validators.compose([Validators.required])],
				OpportunityTags: [""],
				reason: [""],
			});
		}
		if (this.opportunityStageList.length > 0) {
			const toSelect = this.opportunityStageList.find((c) => c.Value == 1);
			this.opportunityForm.get("OpportunityStatusId").setValue(toSelect);
			this.ref.detectChanges();
		}
	}

	initQuickTaskForm() {
		this.quickTaskForm = this.fb.group({
			LeadId: [this.customer.Id],
			ActionPointId: ["", Validators.required],
			ActionPointText: [""],
			NextActionDate: ["", Validators.required],
			IsAddQuickTaskOnly: [true],
			AssignTaskToUserId: [""],
			ProductIds: [""],
		});
	}

	/**
	 * Call Log
	 */
	addCallLog() {
		this.buttonDisabled = true;
		this.clearData = false;
		const controls = this.callLogForm.controls;
		if (this.callLogForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}
		this.viewLoading = true;
		const resControls = this.callLogForm.controls;
		const _callLog = new CallLogModel();
		_callLog.LeadId = resControls["LeadId"].value;
		_callLog.LeadId = this.customer.Id;
		_callLog.Note = resControls["Note"].value;
		_callLog.IsCallLog = resControls["IsCallLog"].value;
		_callLog.IsCallLog = true;
		_callLog.ProductIds = [];
		if (resControls["StatusId"].value == null) {
			_callLog.StatusId = this.customer.StatusId;
			if (resControls["VisitPurposeIds"] && resControls["VisitPurposeIds"].value && resControls["VisitPurposeIds"].value.multipleCheckInputValue !== null) {
				_callLog.VisitPurposeIds = resControls["VisitPurposeIds"].value.multipleCheckInputValue;
			}
		} else {
			_callLog.StatusId = resControls["StatusId"].value.selectInputValue;
			if (resControls["VisitPurposeIds"] && resControls["VisitPurposeIds"].value && resControls["VisitPurposeIds"].value.multipleCheckInputValue) {
				_callLog.VisitPurposeIds = resControls["VisitPurposeIds"].value.multipleCheckInputValue;
				this.tempVisitPurposeIds = resControls["VisitPurposeIds"].value.multipleCheckInputValue;
			}
			this.tempStatusId = resControls["StatusId"].value.selectInputValue;
		}

		if (this.callLogForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			this.viewLoading = false;
			return;
		}

		this.customersService
			.addCallLog(_callLog)
			.pipe(
				map((res) => {
					if (res.Status == 200) {
						setTimeout(() => {
							this.toastrService.success("Call Log Added Successfully");
							this.reloadBoolean.emit(this.reload);
							this.clearData = true;
							this.closeDropdown();
							this.initCallLogForm();
							this.callLogForm.reset();
							this.buttonDisabled = true;
						}, 2000);
					} else if (res.Status == 417) {
						this.buttonDisabled = false;
						this.toastrService.error("Something Went Wrong");
					} else {
						this.buttonDisabled = false;
						this.viewLoading = false;
						this.toastrService.error("Something Went Wrong");
					}
				})
			)
			.subscribe();
	}

	/**
	 * Appointment
	 */
	removeAppointmentDate(date: string) {
		const index: number = this.appointmentDateList.indexOf(date);
		if (index !== -1) {
			this.appointmentDateList.splice(index, 1);
		}
	}

	addAppointment() {
		this.clearData = false;
		const controls = this.appointmentForm.controls;
		if (this.appointmentForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}
		this.viewLoading = true;
		const resControls = this.appointmentForm.controls;
		const _appointment = { LeadIdsWithDate: [] };
		if (resControls["AssignedToUserId"].value != null) {
			if (resControls["AssignedToUserId"].value.singleCheckInputValue) {
				this._userId = resControls["AssignedToUserId"].value.singleCheckInputValue;
			} else {
				this._userId = this.currentUser.userId;
			}
		} else {
			resControls["LeadId"].setValue(this.customer.Id);
			this._userId = this.currentUser.userId;
		}

		for (let appointment of this.appointmentDateList) {
			_appointment.LeadIdsWithDate = [
				{
					LeadId: this.customer.Id,
					UserId: this._userId,
					Date: [appointment],
				},
			];

			this.customersService
				.addAppointment(_appointment)
				.pipe(
					map((res) => {
						if (res.Status == 200) {
							this.toastrService.success("Appointment Added Successfully");
							this.clearData = true;
							this.closeDropdown();
						} else {
							this.viewLoading = false;
						}
					})
				)
				.subscribe();
		}
		this.viewLoading = false;
		this.appointmentDateList = [];
		this.ref.detectChanges();
	}

	/**
	 * Opportunity
	 */
	addOpportunity() {
		this.clearData = false;
		const controls = this.opportunityForm.controls;
		var selectedReasons = [];
		var flag = 0;
		if (this.selectedValue == 1 || this.selectedValue == 2 || this.selectedValue == 3 || this.selectedValue == 4) {
			this.opportunityForm.controls["reason"].setValidators([]);
			this.opportunityForm.controls["reason"].updateValueAndValidity();
			flag = 1;
		}
		if (this.selectedValue == 5 && this.isLostReasonRequired == true) {
			this.opportunityForm.controls["reason"].setValidators(Validators.required);
			this.opportunityForm.controls["reason"].updateValueAndValidity();
			flag = 0;
		}
		if (this.opportunityForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}
		this.viewLoading = true;
		const resControls = this.opportunityForm.controls;
		const _opportunity = new OpportunityModel();
		if (flag == 0) {
			var reasonIds = resControls["reason"].value;
			var tempReasons = {};
			if (!reasonIds) {
				reasonIds = [];
			}
			reasonIds.forEach((reason) => {
				tempReasons = { ReasonId: reason };
				selectedReasons.push(tempReasons);
			});
			if (reasonIds) {
				_opportunity.OpportunityReasons = selectedReasons;
			} else {
				_opportunity.OpportunityReasons = [];
			}
		}
		_opportunity.LeadId = resControls["LeadId"].value;
		_opportunity.LeadId = this.customer.Id;
		_opportunity.OpportunityName = resControls["OpportunityName"].value;
		_opportunity.OpportunityStatusId = resControls["OpportunityStatusId"].value.Value;
		_opportunity.Value = resControls["Value"].value;
		_opportunity.CloseDate = this.customDatePipe.transform(resControls["CloseDate"].value.date, 0, "MM/dd/yyyy");
		if (resControls["OpportunityTags"].value != null) {
			_opportunity.OpportunityTags = resControls["OpportunityTags"].value.Tags;
		}
		if (resControls["AssignedToUserId"].value != null) {
			if (resControls["AssignedToUserId"].value.singleCheckInputValue) {
				_opportunity.AssignedToUserId = resControls["AssignedToUserId"].value.singleCheckInputValue;
			} else {
				_opportunity.AssignedToUserId = this.customer.UserId;
			}
		} else {
			_opportunity.AssignedToUserId = this.customer.UserId;
		}
		this.customersService
			.addOpportunity(_opportunity)
			.pipe(
				map((res) => {
					if (res.Status == 200) {
						this.toastrService.success("Opportunity Added Successfully");
						this.clearData = true;
						this.closeDropdown();
					} else {
						this.viewLoading = false;
					}
				})
			)
			.subscribe();
	}

	/**
	 * Quick Task
	 */
	addQuickTask() {
		this.clearData = false;
		const controls = this.quickTaskForm.controls;
		if (this.quickTaskForm.invalid) {
			Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
			return;
		}
		this.viewLoading = true;
		const resControls = this.quickTaskForm.controls;
		const _quickTask = new QuickTaskModel();
		_quickTask.LeadId = resControls["LeadId"].value;
		_quickTask.LeadId = this.customer.Id;
		_quickTask.ActionPointId = resControls["ActionPointId"].value.selectInputValue;
		_quickTask.ActionPointText = resControls["ActionPointId"].value.selectInputText;
		if (_quickTask.ActionPointId == null || _quickTask.ActionPointText == null) {
			return;
		}
		let finalDate = this.customDatePipe.transform(resControls["NextActionDate"].value.date, 0, "dd MMM yyyy");
		let finalTime = this.selectedTimePicker;
		_quickTask.NextActionDate = finalDate + " " + finalTime;
		_quickTask.IsAddQuickTaskOnly = resControls["IsAddQuickTaskOnly"].value;
		_quickTask.IsAddQuickTaskOnly = true;
		if (resControls["AssignTaskToUserId"].value != null) {
			if (resControls["AssignTaskToUserId"].value.singleCheckInputValue) {
				_quickTask.AssignTaskToUserId = resControls["AssignTaskToUserId"].value.singleCheckInputValue;
			} else {
				_quickTask.AssignTaskToUserId = this.customer.UserId;
			}
		} else {
			_quickTask.AssignTaskToUserId = this.customer.UserId;
		}
		this.customersService
			.addQuickTask(_quickTask)
			.pipe(
				map((res) => {
					if (res.Status == 200) {
						this.toastrService.success("Quick Task Added Successfully");
						this.clearData = true;
						this.closeDropdown();
						this.ngOnInit();
					} else {
						this.viewLoading = false;
					}
				})
			)
			.subscribe();
	}

	selectedTime(time) {
		this.showPicker = false;
		let meridian = "";
		if (time.minute < 10) {
			time.minute = "0" + time.minute;
		}
		if (time.hour < 10) {
			time.hour = "0" + time.hour;
		}
		if (time.hour > 11) {
			meridian = "PM";
		} else {
			meridian = "AM";
		}
		switch (time.hour) {
			case 1:
			case 13: {
				time.hour = "01";
				break;
			}
			case 2:
			case 14: {
				time.hour = "02";
				break;
			}
			case 3:
			case 15: {
				time.hour = "03";
				break;
			}
			case 4:
			case 16: {
				time.hour = "04";
				break;
			}
			case 5:
			case 17: {
				time.hour = "05";
				break;
			}
			case 6:
			case 18: {
				time.hour = "06";
				break;
			}
			case 7:
			case 19: {
				time.hour = "07";
				break;
			}
			case 8:
			case 20: {
				time.hour = "08";
				break;
			}
			case 9:
			case 21: {
				time.hour = "09";
				break;
			}
			case 10:
			case 22: {
				time.hour = "10";
				break;
			}
			case 11:
			case 23: {
				time.hour = "11";
				break;
			}
			case 12:
			case 24: {
				time.hour = "12";
				break;
			}
		}
		this.selectedTimePicker = time.hour + ":" + time.minute + " " + meridian;
		let appointmentDate = this.appointmentForm.controls["Date"].value.date._d;
		let aD = this.customDatePipe.transform(appointmentDate, 0, "MM/dd/yyyy");
		let cu = this.customDatePipe.transform(this.current, 0, "MM/dd/yyyy");
		var newAD = new Date(aD);
		var newCU = new Date(cu);
		if ((this.selectedTimePicker > this.outputTime && newAD.getTime() == newCU.getTime()) || appointmentDate.getTime() > this.current.getTime()) {
			this.errorMessage = "";
			this.finalDate();
		} else {
			this.errorMessage = "Please Select Valid Time";
		}
	}

	finalDate() {
		if (this.appointmentForm.controls["Date"].value && this.selectedTimePicker) {
			var value = this.appointmentForm.controls["Date"].value.date;
			let newvalue = value.toString();
			let newDate = this.customDatePipe.transform(newvalue, 0, "dd MMM yyyy");
			let finalDate = newDate + " " + this.selectedTimePicker;
			if (!this.appointmentDateList.includes(finalDate)) {
				this.appointmentDateList.push(finalDate);
			}
		}
	}

	clickedToggle(toggleId) {
		this.clickedAction.emit(toggleId);
	}

	/**
	 * close dropdown
	 */
	closeDropdown() {
		this.dropdown1.close();
		this.dropdown2.close();
		this.dropdown3.close();
		this.dropdown4.close();
		this.callLogForm.reset();
		this.appointmentForm.reset();
		this.opportunityForm.reset();
		this.quickTaskForm.reset();
		this.appointmentDateList = [];
		this.viewLoading = false;
		this.ref.detectChanges();
	}
}
